<template>
	<div>
		<v-layout class="pa-2 border-bottom-light-grey min-height-40px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/files-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				Files
			</v-flex>
			<v-flex class="text-right" v-if="type != 'project'">
				<v-btn
					v-on:click="attachDialog = true"
					color="blue darken-4 text-white"
					class="ml-2"
					depressed
					tile
					><v-icon small left>mdi-playlist-plus</v-icon>Attach File</v-btn
				>
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 345px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th class="p-2 light-blue-bg" width="50"></th>
						<th class="p-2 light-blue-bg">Name</th>
						<th class="p-2 light-blue-bg">Date</th>
						<th class="p-2 light-blue-bg" width="20%">Tags</th>
						<th class="p-2 light-blue-bg">Created At</th>
						<th class="p-2 light-blue-bg">Action</th>
					</tr>
				</thead>
				<tbody v-if="dbFiles.length">
					<tr v-for="(row, index) in dbFiles" :key="index">
						<td class="p-2 border-top-light-grey" width="50">
							<template
								v-if="
									getFileExtension(row.url) == 'jpg' ||
									getFileExtension(row.url) == 'jpeg' ||
									getFileExtension(row.url) == 'png' ||
									getFileExtension(row.url) == 'jfif'
								"
							>
								<ImageTemplate style="max-width: 50px; width: 50px" :src="row.url"></ImageTemplate>
							</template>
							<template v-if="getFileExtension(row.url) == 'pdf'">
								<inline-svg style="max-width: 50px; width: 50px" :src="$assetURL('media/mime/pdf.svg')" />
							</template>
							<template v-if="getFileExtension(row.url) == 'docx' || getFileExtension(row.url) == 'doc'">
								<inline-svg style="max-width: 50px; width: 50px" :src="$assetURL('media/mime/doc.svg')" />
							</template>
							<template v-if="getFileExtension(row.url) == 'xls' || getFileExtension(row.url) == 'xlsx'">
								<inline-svg style="max-width: 50px; width: 50px" :src="$assetURL('media/mime/xls.svg')" />
							</template>
							<template v-if="getFileExtension(row.url) == 'gif' || getFileExtension(row.url) == 'gif'">
								<inline-svg style="max-width: 50px; width: 50px" :src="$assetURL('media/mime/gif.svg')" />
							</template>
						</td>
						<td class="p-2 border-top-light-grey">
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<p
										v-bind="attrs"
										v-on="on"
										class="m-0 blue--text text--darken-4 font-level-1 text_overflow"
									>
										{{ row.name }}
									</p>
								</template>
								<span>{{ row.name }}</span>
							</v-tooltip>
							<div>
								<v-flex> {{ getFileSize(row.size) }} KB </v-flex>
							</div>
							<span class="text-muted font-small"
								><!-- {{ formatDate(row.added_at) }} -->
								<!-- <v-chip v-if ="row && row.meeting_barcode || row.task_barcode" class="mb-1 mr-1" label color="red white--text" x-small :text="row.type">
								<template v-if="row && row.meeting_barcode && row.task_barcode == null"><span v-if="row && row.meeting_barcode">{{ row.meeting_barcode }}</span></template>		
								   <span v-if="row && row.task_barcode && row.meeting_barcode == null">{{ row.task_barcode }}</span>											
										</v-chip> -->
								<div
									v-if="(row && row.meeting_barcode) || row.task_barcode || row.customer_barcode"
									class="mb-1 mr-1"
									label
									color="red white--text"
									x-small
									:text="row.type"
								>
									<v-chip
										class="mb-1 mr-1"
										label
										color="red white--text"
										x-small
										v-if="row && row.meeting_barcode && row.task_barcode == null"
									>
										<span v-if="row && row.meeting_barcode">{{ row.meeting_barcode }}</span>
									</v-chip>
									<v-chip
										class="mb-1 mr-1"
										label
										color="cyan white--text"
										x-small
										v-if="row && row.task_barcode && row.meeting_barcode == null"
									>
										<span v-if="row && row.task_barcode">{{ row.task_barcode }}</span>
									</v-chip>
									<v-chip
										class="mb-1 mr-1"
										label
										color="blue white--text"
										x-small
										v-if="
											row && row.task_barcode == null && row.meeting_barcode == null && row.customer_barcode
										"
									>
										<span v-if="row && row.customer_barcode">{{ row.customer_barcode }}</span>
									</v-chip>
								</div>
								<!-- <v-chip
										class="mb-1 mr-1"
										small
										><v-avatar left small>
												
													<img v-if="row.profile_img" :src="row.profile_img" />
														<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
										</v-avatar>
										<span v-if="row && row.display_name">{{ row.display_name }}</span>
									</v-chip> -->
								<!-- by {{ row.created_by }} --></span
							>
						</td>

						<td class="p-2 border-top-light-grey">
							<div class="d-flex">
								<span class="d-inline-block text-end me-1" style="width: 65px">Start </span>:&nbsp;&nbsp;
								<span class="d-flex" v-if="row.start_date"
									><v-icon small>mdi-calendar</v-icon> {{ formatDate(row.start_date) }}
								</span>
								<span v-else><v-icon small>mdi-calendar</v-icon>- </span>
							</div>
							<div class="d-flex">
								<span class="d-inline-block text-end me-1" style="width: 65px">End </span>:&nbsp;&nbsp;
								<span class="d-flex" v-if="row.end_date"
									><v-icon small>mdi-calendar</v-icon> {{ formatDate(row.end_date) }}
								</span>
								<span v-else><v-icon small>mdi-calendar</v-icon>- </span>
							</div>
							<div class="d-flex">
								<span class="d-inline-block text-end me-1" style="width: 65px">Reminder </span>:&nbsp;&nbsp;
								<span class="d-flex" v-if="row.reminder_date"
									><v-icon small>mdi-calendar</v-icon> {{ formatDate(row.reminder_date) }}
								</span>
								<span v-else><v-icon small>mdi-calendar</v-icon>- </span>
							</div>
						</td>
						<td class="p-2 border-top-light-grey font-level-1">
							<template v-if="row.tags">
								<div v-for="(tag, index) in row.tags" :key="index">
									<v-chip class="mb-1 px-1 mr-1 text-white" x-small :color="tag.color">{{
										tag.text
									}}</v-chip>
								</div>
							</template>
							<span v-else>no tags</span>
						</td>
						<td class="p-2 border-top-light-grey">
							<v-chip class="mb-1 mr-1" small
								><v-avatar left small>
									<img v-if="row.profile_img" :src="row.profile_img" />
									<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
								</v-avatar>
								<span v-if="row && row.display_name">{{ row.display_name }}</span> </v-chip
							><br />
							<v-chip class="mb-1 mr-1" small
								><span><v-icon>mdi-clock-outline</v-icon> {{ formatDate(row.added_at) }} </span></v-chip
							>
						</td>
						<td class="p-2 border-top-light-grey font-level-1">
							<div class="d-flex">
								<v-btn
									v-on:click="doAction(row, 'download')"
									small
									icon
									depressed
									outlined
									color="blue darken-4"
									class="mr-2"
									><v-icon small>mdi-download</v-icon></v-btn
								>
								<v-btn
									small
									outlined
									v-on:click="doAction(row, 'delete')"
									icon
									depressed
									color="red lighten-1"
									><v-icon small>mdi-delete</v-icon></v-btn
								>
							</div>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="6">
							<p class="m-0 row-not-found text-center">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no file at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
		<add-dialog
			:document-dialog="attachDialog"
			v-on:close="attachDialog = false"
			v-on:success="getFiles()"
			:related-id="typeId"
			:related-type="type"
		></add-dialog>

		<Dialog :dialog="deleteDialog" :dialog-width="600">
			<template v-slot:title> Delete File</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">Deleting File is irreversible, Are you sure about deleting it?</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					:loading="deleteLoading"
					:disabled="deleteLoading"
					depressed
					color="red lighten-1"
					tile
					v-on:click="deleteFile()"
				>
					Yes! Delete
				</v-btn>
				<v-btn depressed tile :disabled="deleteLoading" v-on:click="deleteDialog = false">
					No, Close
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import { toNumber, round, last } from "lodash";
import ImageTemplate from "@/view/components/Image";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import AddDialog from "@/view/pages/leads/create/AddDialog";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	props: {
		typeText: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		url: {
			type: String,
			default: null,
		},
		typeId: {
			type: Number,
			default: 0,
		},
		typeUuid: {
			type: String,
			default: null,
		},
	},
	watch: {
		typeId: {
			handler(param) {
				if (param) {
					this.getFiles();
				}
			},
		},
	},
	data() {
		return {
			pageLoading: true,
			deleteLoading: false,
			deleteDialog: false,
			attachDialog: false,
			attachLoading: false,
			id: 0,
			description: null,
			dbFiles: [],
		};
	},
	methods: {
		init() {
			this.deleteLoading = false;
			this.deleteDialog = false;
			this.attachDialog = false;
			this.attachLoading = false;
			this.id = null;
			this.description = null;
		},
		getFileExtension(path) {
			if (path) {
				return last(path.split("."));
			}
		},
		getFileSize(size) {
			return round(toNumber(size) / 1024, 2);
		},

		doAction(row, param) {
			switch (param) {
				case "download":
					window.open(row.url, "_blank");
					break;
				case "delete":
					this.id = row.id;
					this.deleteDialog = true;
					break;
			}
		},
		deleteFile() {
			if (!this.id) {
				return false;
			}
			this.deleteLoading = true;
			ApiService.delete(`${this.url}/${this.typeId}/${this.type}/attachment/${this.id}`)
				.then(() => {
					this.deleteLoading = false;
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! File Deleted successfully." },
						this.$emit("success", true),
					]);
					this.getFiles();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {});
		},
		getFiles() {
			ApiService.get(`${this.url}/${this.typeId}/${this.type}/attachment`)
				.then(({ data }) => {
					this.dbFiles = data;
					this.init();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		Dialog,
		ImageTemplate,
		AddDialog,
	},
	mounted() {
		this.getFiles();
	},
};
</script>
<style scoped>
.text_overflow {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100px;
}
</style>
