<template>
	<Dialog :dialog.sync="documentDialog" @close="$emit('close')">
		<template v-slot:title>Upload File</template>
		<template v-slot:body>
			<v-form
				ref="documentForm"
				v-model.trim="export_valid"
				lazy-validation
				v-on:submit.stop.prevent="submitDocument()"
			>
				<table style="table-layout: fixed" class="table-document" width="100%">
					<tr>
						<td colspan="2" width="100%" class="px-2 pb-3">
							<label for="file" class="font-weight-600 required mb-1">Upload File </label>
							<!-- <v-icon size="60">mdi-cloud-upload</v-icon>
        <div class="text-h5 text-secondary">Click here to select file</div> -->
							<v-file-input
								placeholder="Select File"
								id="document-file"
								outlined
								class="mt-1"
								prepend-icon=""
								prepend-inner-icon="mdi-attachment"
								hide-details
								v-model="file.file"
								v-on:change="updateFile($event)"
								v-on:click:clear="updateFile($event)"
							></v-file-input>
						</td>
					</tr>
					<tr>
						<td colspan="2" width="100%" class="px-2 pb-3">
							<label for="file" class="font-weight-600 required mb-1">File Name</label>
							<TextInput
								id="document-name"
								v-model="file.name"
								hide-details
								placeholder="File Name"
								:suffix="file.suffix"
								hideTopMargin="mt-2"
							></TextInput>
						</td>
					</tr>
					<tr>
						<td width="50%" class="px-2 pb-3">
							<label for="start-date" class="font-weight-600 mb-1">Start Date</label>
							<DatePicker
								hideTopMargin="mt-2"
								hide-details
								clearable
								:disabled="pageLoading"
								:loading="pageLoading"
								id="start-date"
								:min-date="minDate"
								placeholder="Start Date"
								v-model="start_date"
							></DatePicker>
						</td>
						<td width="50%" class="px-2 pb-3">
							<label for="end-date" class="font-weight-600 mb-1">End Date</label>
							<DatePicker
								hideTopMargin="mt-2"
								hide-details
								clearable
								:disabled="pageLoading"
								:loading="pageLoading"
								id="end-date"
								placeholder="End Date"
								:min-date="start_date"
								v-model="end_date"
							></DatePicker>
						</td>
					</tr>
					<tr>
						<td colspan="2" class="px-2 pb-3">
							<label for="reminder" class="font-weight-600 mb-1">Reminder Date</label>
							<DatePicker
								hideTopMargin="mt-1"
								hide-details
								clearable
								:disabled="pageLoading"
								:loading="pageLoading"
								id="reminder-date"
								placeholder="Reminder Date"
								:min-date="minDate"
								v-model="reminder_date"
							></DatePicker>
						</td>
					</tr>
					<tr>
						{{
							file.tags
						}}
						<td colspan="2" class="px-2">
							<label for="file" class="font-weight-600 mb-1">Tags</label>
							<TagsAutoSearch type="opportunity" v-model="tags" />
						</td>
					</tr>
				</table>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				:disabled="!export_valid || attachLoading"
				:loading="attachLoading"
				class="white--text"
				depressed
				color="blue darken-4"
				tile
				v-on:click="submitDocument()"
			>
				Submit
			</v-btn>
			<v-btn
				depressed
				tile
				:disabled="attachLoading"
				:loading="attachLoading"
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import Dialog from "@/view/components/Dialog.vue";
import TextInput from "@/view/components/TextInput";
import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";
import TagsAutoSearch from "@/view/components/TagsAutoSearch";
import MomentJS from "moment-timezone";
import { toString } from "lodash";

export default {
	name: "export-dialog",
	data() {
		return {
			pageLoading: null,
			export_valid: true,
			attachLoading: false,
			minDate: MomentJS().format("YYYY-MM-DD"),
			maxDate: MomentJS().format("YYYY-MM-DD"),

			file: {
				file: null,
				name: null,
				suffix: null,
			},
			start_date: "",
			end_date: "",
			reminder_date: "",
			tags: [],
		};
	},
	props: {
		documentDialog: {
			type: Boolean,
			default: false,
		},
		chat: {
			type: Number,
			default: 1,
		},
		relatedId: {
			type: Number,
			default: 0,
		},
		parentId: {
			type: Number,
			default: 0,
		},
		relatedType: {
			type: String,
			default: null,
		},
	},
	watch: {
		documentDialog(param) {
			if (param) {
				this.file = {
					file: null,
					name: null,
					suffix: null,
				};
			}
		},
	},
	methods: {
		submitDocument() {
			const _this = this;

			console.log(this.start_date, "file");

			const formErrors = _this.validateForm(_this.$refs.documentForm);

			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.documentForm.validate()) {
				return false;
			}

			let errArr = [];

			let cloneFile = cloneDeep(this.file);

			if (!cloneFile.file || !cloneFile.name) {
				if (!cloneFile.file) {
					errArr.push({ model: true, message: "File is missing." });
				}
				if (!cloneFile.name) {
					errArr.push({ model: true, message: "File name is missing." });
				}

				if (errArr.length) {
					this.$store.commit(SET_ERROR, [...errArr]);
					return false;
				}
			}

			const params = new FormData();
			params.append("file", this.file.file);
			params.append("name", this.file.name);
			params.append("chat", this.chat);
			params.append("start_date", this.start_date);
			params.append("end_date", this.end_date);
			params.append("reminder_date", this.reminder_date);
			// params.append("tags", this.tags);
			if (this.tags) {
				console.log(this.tags, "this.tags");
				for (let j = 0; j < this.tags?.length; j++) {
					params.append(`tags[${j}][text]`, toString(this.tags[j].text));
					params.append(`tags[${j}][color]`, toString(this.tags[j].color));
				}
			}
			//   params.append("tags", this.tags);

			// params.append("tags", this.tags);
			// params.tags = this.tags;
			// this.tags.forEach(tag => params.append("tags", tag));

			params.append("related_id", this.relatedId);
			params.append("type", this.relatedType);
			if (this.parentId) {
				params.append("parent", this.parentId);
			}
			this.attachLoading = true;
			console.log(params, "params");
			ApiService.setHeader();
			ApiService.upload("all-deal-documents", params)
				.then(() => {
					this.$emit("success", true);
					this.$emit("close", true);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.attachLoading = false;
				});
		},
		updateFile(file) {
			if (file && file.name) {
				this.file.name = file.name.split(".").slice(0, -1).join(".");
				this.file.suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.file.name = null;
				this.file.suffix = null;
			}
		},
	},
	components: {
		Dialog,
		TextInput,
		TagsAutoSearch,
		DatePicker: () => import("@/view/components/DatePicker"),
	},
};
</script>
